import { ContractServiceApiV2 } from './contract.service'
import { PlategiService } from './plategi.service'
import { UchetService, UchetServiceApiV2 } from './uchet.service'
import { ZaysService, ZaysServiceApiV2 } from './zays.service'
import { UchetPechatService } from './uchet-pechat.service'
import { KeensService, KeensServiceApiV2 } from './keens.service'
import { UchetZoplService } from './uchet-zopl.service'
import { ZatratiService } from './zatrati.service'
import { PlanService, PlanServiceApiV2 } from './plan.service'
import { PlanGruzService } from './plan-gruz.service'
import { DriversService, DriverServiceApiV2 } from './drivers.service'
import { AvtosService, AvtoServiceApiV2 } from './autos.service'
import { PricepsService, PricepServiceApiV2 } from './priceps.service'
import { FirmsService, FirmServiceApiV2 } from './firms.service'
import { UslugiService } from './uslugi.service'
import { Uslugi2ServiceApiV2, Uslugi2Service } from './uslugi2.service'
import { PutlistService, PutlistServiceApiV2 } from './putlist.service'
import { PrinterService } from './printer.service'
import { DelayService } from './delay.service'
import { AuthService, AuthHttpInterceptorService } from './auth.service'
import { EkspServiceApiV2 } from './eksp.service'
import { LegacyUserServiceApiV2 } from './legacy-users.service'
import { TachographsService } from './tachograph.service'
import PopupService from './popup.service'
import { TTNServiceApiV2 } from './ttns.service'
import { EquipmentServiceApiV2 } from './equipment.service'
import { GroupServiceApiV2, GroupChildrenServiceApiV2 } from './group.service'
import { CurrentUserService } from './current-user.service'
import { EquipmentImageServiceApiV2 } from './equipment-image.service'
import angular from 'angular'
import { FuelCostServiceApi2 } from './fuel-cost.service'
import { TarifiServiceApiV2 } from './tarifi.service'

export default angular
  .module('gruz.services', [])
  .service('authService', AuthService)
  .service('authHttpInterceptorService', AuthHttpInterceptorService)
  .service('currentUser', CurrentUserService)
  .service('profile', CurrentUserService)
  .service('printer', PrinterService)
  .service('delaypls', DelayService)
  .service('popup', PopupService)

  // api V1
  .service('avtosService', AvtosService)
  .service('pricepsService', PricepsService)
  .service('pricepServiceApiV2', PricepServiceApiV2)
  .service('plategiService', PlategiService)
  .service('uchetService', UchetService)
  .service('zaysService', ZaysService)
  .service('uchetPechatService', UchetPechatService)
  .service('uchetZoplService', UchetZoplService)
  .service('keensService', KeensService)
  .service('zatratiService', ZatratiService)
  .service('firmsService', FirmsService)
  .service('planService', PlanService)
  .service('planGruzService', PlanGruzService)
  .service('uslugiService', UslugiService)
  .service('uslugi2Service', Uslugi2Service)
  .service('putlistService', PutlistService)
  .service('putlistServiceApiV2', PutlistServiceApiV2)
  .service('tachographService', TachographsService)
  .service('driversService', DriversService)

  // api V2
  .service('avtoServiceApiV2', AvtoServiceApiV2)
  .service('contractServiceApiV2', ContractServiceApiV2)
  .service('driverServiceApiV2', DriverServiceApiV2)
  .service('ekspServiceApiV2', EkspServiceApiV2)
  .service('firmServiceApiV2', FirmServiceApiV2)
  .service('legacyUsersServiceApiV2', LegacyUserServiceApiV2)
  .service('planServiceApiV2', PlanServiceApiV2)
  .service('pricepServiceApiV2', PricepServiceApiV2)
  .service('putlistServiceApiV2', PutlistServiceApiV2)
  .service('uchetServiceApiV2', UchetServiceApiV2)
  .service('zaysServiceApiV2', ZaysServiceApiV2)
  .service('tarifiServiceApiV2', TarifiServiceApiV2)
  .service('ttnsServiceApiV2', TTNServiceApiV2)
  .service('equipmentServiceApiV2', EquipmentServiceApiV2)
  .service('groupServiceApiV2', GroupServiceApiV2)
  .service('groupChildrenServiceApiV2', GroupChildrenServiceApiV2)
  .service('equipmentImageServiceApiV2', EquipmentImageServiceApiV2)
  .service('uslugi2ServiceApiV2', Uslugi2ServiceApiV2)
  .service('fuelCostServiceApiV2', FuelCostServiceApi2)
  .service('keensServiceApiV2', KeensServiceApiV2).name
