import angular from 'angular'

export default angular
  .module('gruz.states.firms', [])
  .config(($stateRegistryProvider) => {
    $stateRegistryProvider.register({
      name: 'firm',
      url: '/firms',
      abstract: true,
    })

    $stateRegistryProvider.register({
      name: 'firm.list',
      url: '/list',
      component: 'firms',
      data: {
        title: 'Фирмы-партнеры',
      },
    })

    $stateRegistryProvider.register({
      name: 'firm.base',
      url: '/{id}',
      abstract: true,
      resolve: {
        firm,
      },
    })

    $stateRegistryProvider.register({
      name: 'firm.add',
      url: '/add',
      component: 'firmForm',
      data: {
        title: 'Новая фирма',
      },
    })

    $stateRegistryProvider.register({
      name: 'firm.base.detail',
      url: '/edit',
      component: 'firmForm',
      resolve: {
        model: (firm) => firm,
      },
    })

    $stateRegistryProvider.register({
      name: 'firm.base.contracts',
      url: '/contracts',
      component: 'contracts',
      resolve: {
        client: (firmServiceApiV2, $transition$) => {
          return firmServiceApiV2.get({ id: $transition$.params().id })
        },
      },
      data: {
        title: 'Договоры фирмы',
      },
    })

    $stateRegistryProvider.register({
      name: 'firm.base.newdriver',
      url: '/add-driver',
      component: 'driverForm',
      resolve: {
        model: (firm) => {
          return { pid: firm.id }
        },
      },
      data: {
        title: 'Новый водитель',
      },
    })
  })

function firm($stateParams, firmServiceApiV2) {
  if ($stateParams.id) {
    return firmServiceApiV2.get({ id: $stateParams.id })
  }
}
