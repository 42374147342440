const menu = require('./menu.json')

/** @ngInject */
class DashboardController {
  constructor($scope) {
    $scope.vm = this
    this.menu = menu

    /**
     * Дополнительные элементы меню.
     * @type {MenuItem}
     */
    this.extraItems = [
      {
        data: {
          zag: 'Реестр оборудования',
          http: 'state:equipments',
          sost: '1',
          width: '1024',
          height: '768',
          roles: ['Реестр оборудования', 'Супервизор'],
        },
        id: 10000,
        children: [],
      },
    ]
  }
}

export const DashboardComponent = {
  template: require('./dashboard.component.html'),
  styles: [require('./dashboard.component.scss')],
  bindings: {
    profile: '<',
  },
  controller: DashboardController,
}
