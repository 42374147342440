export const emptyPlist = {
  data1: new Date(),
  data2: new Date(),
  marshrut: '',
  gde: '',
  realdriver: '',
  realpricep: '',
  weight: 0,
  lcost: 0,
  wcont: 0,
  cover: 0,
  city: 0,
  plistp: {
    speed: 0,
    zima: 0,
    weight: 0,
    _2mil: 0,
    aprob: 0,
    negab: 0,
    psgruz: 0,
    procie: 0,
    time: '',
    inbak: 0,
  },
  plistu: {
    speed: 0,
    ostatok: 0,
    give: 0,
    gsm: 0,
    time: '',
  },
  is_commercial: true,
  shipment_type: 'INTERURBAN',
}

export const plistListState = {
  name: 'putlists',
  url: '/putlists',
  component: 'putlists',
  data: {
    title: 'Путевые листы',
  },
}

export const plistAddState = {
  name: 'putlist.add',
  url: '/plists/add/:id',
  component: 'putlistForm',
  resolve: {
    sourcePlist: (putlistService, $stateParams) =>
      $stateParams.id ? putlistService.get({ id: $stateParams.id }) : undefined,
    plist: (sourcePlist) =>
      Object.assign(emptyPlist, sourcePlist, { id: null }),
    plists: (putlistService) => putlistService.get(),
    plans: (planService) => planService.get(),
    avtos: (avtosService) => avtosService.get(),
    priceps: (pricepsService) => pricepsService.get(),
    drivers: (driversService) => driversService.get(),
    ourFirms: (firmsService) => firmsService.get({ kmbm__nashi: 1 }),
  },
}

export const plistState = {
  name: 'putlist',
  url: '/plists/:id',
  component: 'putlistForm',
  resolve: {
    plans: (planService) => planService.get(),
    plists: (putlistService) => putlistService.get(),
    plist: ($stateParams, putlistService) =>
      putlistService.get({ id: $stateParams.id }),
    avtos: (avtosService) => avtosService.get(),
    priceps: (pricepsService) => pricepsService.get(),
    drivers: (driversService) => driversService.get(),
    ourFirms: (firmsService) => firmsService.get({ kmbm__nashi: 1 }),
  },
}
