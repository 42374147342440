import { settings } from '../../app.config'

/** @ngInject */
function ZaysController($scope, $q, $http, $timeout, popup) {
  const FIRMALIAS = {
    zkm: 'ЗКМ',
    okm: 'ОКМ',
    tm: 'ТМ',
    bm: 'БМ',
    th: 'ТХ',
    kl: 'КЛ',
  }

  const vm = this

  Object.assign(vm, {
    currentUser: null,
    selected: null,
    zays: $('#zays'),
    filter_type: '',
    filter: '',
    init: () => {
      vm.zays
        .jsTable({
          headers: {
            id: '№',
            type_alias: 'По&nbsp;фирме',
            data: 'Дата',
            __verbose__plid: 'Плательщик',
            pergruz: 'Груз',
            __verbose__autor: 'Автор (менеджер)',
            // '__actions': ''
          },
          tablesorterOptions: {
            sortList: [[0, 1]],
            headers: {
              2: { sorter: 'dd.mm.yyyy' },
            },
          },
        })
        .jsTable('loadRows', [])
        .jsTable('option', 'onRowClick', () => {
          $timeout(() => {
            vm.selected = vm.zays.jsTable('getSelectedRowData')
          })
        })
        .jsTable('option', 'onRowDblClick', () => {
          $timeout(vm.edit)
        })
        .jsTable('option', 'rowRenderer', vm.renderRow)

      let timeoutPromise
      const delayInMs = 500
      $scope.$watch('vm.filter', filterHandler)
      $scope.$watch('vm.filter_type', filterHandler)

      function filterHandler(nv, ov) {
        if (nv === ov) {
          return
        }
        $timeout.cancel(timeoutPromise) // does nothing, if timeout alrdy done
        timeoutPromise = $timeout(function () {
          // Set timeout
          console.log('searching...')
          $scope.loading = true
          vm.search()
        }, delayInMs)
      }

      $('#search').focus()
      $q.all([
        vm.reload('api.php?url=zays&__verbose=1'),
        $http.get('api.php?url=profile'),
      ]).then((resps) => {
        vm.currentUser = resps[1].data
      })
    },
    add: () => {
      return $.when(popup.state('zay.new', 800, 600)).then((zay) => {
        vm.selected = zay
        return vm.search().then(() => {
          vm.zays.jsTable('selectRowById', zay.id)
        })
      })
    },
    edit: () => {
      return $.when(
        popup.state('zay.base.detail', { id: vm.selected.id }, 800, 600)
      ).then((zay) => {
        vm.selected = zay
        return vm.search().then(() => {
          vm.zays.jsTable('selectRowById', zay.id)
        })
      })
    },
    finansy: (id) => {
      if (!id) {
        id = vm.selected.id
      }
      return popup.state('zay.base.finansy', { id }, 1024, 768)
    },
    delete: () => {
      if (vm.selected.autor !== vm.currentUser.id) {
        alert('Заявку может удалить только ее автор.')
        return
      }

      if (!confirm('Вы уверены, что хотите удалить выбранную заявку?')) {
        return
      }

      $http.delete(`${settings.apiUrl}/api/v2/zays/${vm.selected.id}`).then(
        () => {
          vm.selected = null
          return vm.search()
        },
        (e) => {
          alert(`Ошибка: ${e.data}`)
        }
      )
    },
    close: () => {
      window.close()
    },
    reload: (url) => {
      if (!url) {
        url = 'api.php?url=zays'
      }
      return $http.get(url).then((resp) => {
        resp.data.forEach((z) => {
          z.type_alias = FIRMALIAS[z.type]
        })
        vm.zays.jsTable('loadRows', resp.data)
      })
    },
    search: () => {
      const filters = {
        __verbose: 1,
        _type: 'query',
        term: vm.filter,
        type: vm.filter_type,
      }
      const q = $.fn.buildQuery(filters)
      return vm.reload(`api.php?url=zays&${q}`)
    },
    clearFilter: () => {
      vm.filter = ''
      vm.filter_type = ''
    },
    onFilterKeyDown: (e) => {
      if (e.key === 'Enter') {
        const first = vm.zays.jsTable('getFirst')
        vm.zays.jsTable('selectRow', first)
      }
    },
    renderRow: (row) => {
      return row
    },
  })

  vm.init()
}

export const ZaysComponent = {
  template: require('./zays.component.html'),
  styles: [require('./zays.component.scss')],
  bindings: {},
  controller: ZaysController,
  controllerAs: 'vm',
}
