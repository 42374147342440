/** @ngInject */
class SvodkaFormController {
  constructor($scope) {
    $scope.vm = this
  }
}

export const SvodkaFormComponent = {
  template: require('./svodka-form.component.html'),
  styles: [require('./svodka-form.component.scss')],
  bindings: {
    zay: '<',
    svodka: '<',
  },
  controller: SvodkaFormController,
  controllerAs: 'vm',
}
