class TarifiFormController {
  constructor($scope, tarifiServiceApiV2) {
    $scope.vm = this
    this.tarifiServiceApiV2 = tarifiServiceApiV2
  }

  save() {
    this.tarifi.results.forEach((t) => {
      this.tarifiServiceApiV2.save(t)
    })
  }
}

export const TarifiFormComponent = {
  template: require('./tarifi-form.component.html'),
  styles: [require('./tarifi-form.component.scss')],
  bindings: {
    tarifi: '<',
  },
  controller: TarifiFormController,
}
