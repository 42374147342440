import angular from 'angular'

export default angular
  .module('gruz.states.tachographs', [])
  .config(($stateRegistryProvider) => {
    $stateRegistryProvider.register({
      name: 'tach',
      url: '/avto/tachographs',
      abstract: true,
    })

    $stateRegistryProvider.register({
      name: 'tach.list',
      url: '/list',
      component: 'tachographs',
      resolve: {},
      data: {
        title: 'Тахографы',
      },
    })

    $stateRegistryProvider.register({
      name: 'tach.new',
      url: '/new',
      component: 'tachForm',
      data: {
        title: 'Создание тахографа',
      },
    })

    $stateRegistryProvider.register({
      name: 'tach.base',
      url: '/{id}',
      abstract: true,
    })

    $stateRegistryProvider.register({
      name: 'tach.base.edit',
      url: '/edit',
      component: 'tachForm',
      resolve: {
        tach: ($stateParams, tachographService) =>
          tachographService.get({ id: $stateParams.id }),
      },
      data: {
        title: 'Редактирование тахографа',
      },
    })
  })
