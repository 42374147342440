import { TachDialogCtrl } from './tach.dialog'
import { UrlBindController } from './urlbind'
import { LoginController } from './login'
import angular from 'angular'
import { ListController } from './list-controller.class'
import { JsTableController } from '../components/js-table/js-table.component'

export default angular
  .module('gruz.controllers', [])
  .controller('gruz.tachDialog', TachDialogCtrl)
  .controller('gruz.urlbind', UrlBindController)
  .controller('gruz.login', LoginController)
  .controller('listController', ListController)
  .controller('jsTableController', JsTableController).name
