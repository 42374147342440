import { FIRM_NDS } from '../../constants'
import PopeyeModal from '../popeye-modal.class'

export default class UchetModal extends PopeyeModal {
  modal = {
    template: require('./uchet.modal.html'),
    controller: function (
      $scope,
      uchet,
      zay,
      type,
      ourfirms,
      planGruzService,
      uchetService,
      popup
    ) {
      const vm = {
        type,
        ourfirms: ourfirms.filter((f) => f.kmbm !== 'tm'),
        uchet: uchet || {
          id: null,
          data: new Date(),
          pid: +zay.id,
          inner: type === 'prihod' ? '0' : '1',
          nds: FIRM_NDS[zay.type],
          primech: '',
          cur: 'rur',
          nsubid: 0,
          oldcur: '',
          oldrub: '0',
          veksp: 0, // TODO make field
          nscheta: null,
          firm: zay.type,
        },
        getZayDohod: () => {
          if (!zay) {
            return
          }

          // если "плательщик" наш - то счет внутренний
          // const plidIsOurs = !!vm.ourFirmsIdx[zay.plid];
          // vm.uchet.inner = plidIsOurs? '1' : '0';

          // берем сумму из заявки
          vm.uchet.summa = zay.dohod

          // вознаргаждение экспедитора (см. #8)
          if (vm.vekspVisible) {
            if (vm.uchet.summa < 500000) {
              vm.uchet.veksp = zay.dohod * 0.2
            } else if (vm.uchet.summa >= 500000 && vm.uchet.summa < 1500000) {
              vm.uchet.veksp = zay.dohod * 0.1
            } else {
              vm.uchet.veksp = 0
            }
            vm.uchet.veksp = Math.round(vm.uchet.veksp / 1000) * 1000
          } else {
            vm.uchet.veksp = 0
          }

          // НДС
          vm.uchet.nds = FIRM_NDS[zay.type]
        },
        save: () => {
          return planGruzService.get({ pid: zay.id }).then((gruzes) => {
            if (gruzes.length <= 0) {
              alert(`По заявке ${zay.id} нет накладных`)
              return
            }
            return uchetService.save(vm.uchet).then((uchet) => {
              $scope.$close(uchet)
            })
          })
        },
        reset: () => {},
        oformlenie: () => {
          return popup.state(
            'invoices.base.oformlenie',
            { id: vm.uchet.id },
            1024,
            600
          )
        },
        plategi: () => {
          return popup.state(
            'invoices.base.plategi',
            { id: vm.uchet.id },
            680,
            600
          )
        },
      }
      Object.defineProperty(vm, 'vekspVisible', {
        get: () =>
          vm.uchet
            ? !(
                (vm.uchet.inner === '1' && vm.uchet.firm === 'tm') ||
                vm.uchet.firm !== 'tm'
              )
            : false,
      })

      if (type === 'rashod') {
        vm.uchet.is_subschet = 1
      }

      $scope.vm = vm
    },
  }
}
