import { settings } from '../../app.config'
// eslint-disable-next-line max-len
import { PrintFormController } from '../../controllers/print-form-controller.class'
import utils, { currentMonth } from '../../utils'

class BalansController extends PrintFormController {
  /** @ngInject */
  constructor($scope, $injector, $q, $filter, zaysServiceApiV2, popup) {
    super($scope, $injector)
    this.zaysServiceApiV2 = zaysServiceApiV2
    this.popup = popup
    this.$filter = $filter

    const nf = $filter('number')

    this.zaysTable = {
      options: {
        headers: {
          id: 'Заявка',
          data: 'Дата',
          manager__fio: 'Менеджер',
          plid__comp: 'Плательщик',
          expected_income_summ_: 'Прогн. доход',
          expected_outcome_summ_: 'Прогн. расход',
          invoice_income_summ_: 'Приход (выставлено)',
          invoice_outcome_summ_: 'Расход (выставлено)',
          paid_income_summ_: 'Оплачено нам',
          not_paid_income_summ_: 'Не оплачено нам',
          paid_outcome_summ_: 'Оплатили мы',
          not_paid_outcome_summ_: 'Не оплатили мы',
          invoice_voznexp_summ_: 'Вознаграждение экспедитора',
        },
      },
      init$: $q.defer(),
      service: zaysServiceApiV2,
      ctrl: null,
      rowfmt: (row) => {
        row.expected_income_summ_ = nf(row.expected_income_summ)
        row.expected_outcome_summ_ = nf(row.expected_outcome_summ)
        row.invoice_income_summ_ = nf(row.invoice_income_summ)
        row.invoice_outcome_summ_ = nf(row.invoice_outcome_summ)
        row.paid_income_summ_ = nf(row.paid_income_summ)
        row.not_paid_income_summ_ = nf(row.not_paid_income_summ)
        row.paid_outcome_summ_ = nf(row.paid_outcome_summ)
        row.not_paid_outcome_summ_ = nf(row.not_paid_outcome_summ)
        row.invoice_voznexp_summ_ = nf(row.invoice_voznexp_summ)
        return row
      },
    }

    this.zayId = null
  }

  get defaultFilters() {
    const invoiceFirms = {}
    this.ourFirms.results.map((firm) => (invoiceFirms[firm.kmbm] = true))

    return {
      m1: currentMonth() + 1,
      m2: currentMonth() + 1,
      typef: 'plat',
      opzayy: 'excl',
      zaysin: '',
      invoiceFirms,
      finstat: '',
      zaid: null,
      plid: null,
    }
  }

  get convertedFilters() {
    const invoiceFirms = []
    for (const key in this.filters.invoiceFirms) {
      if (this.filters.invoiceFirms[key]) {
        invoiceFirms.push(key)
      }
    }
    return {
      id__in: this.filters.zaysin.replaceAll(' ', '').split(','),
      date_from: utils.db.date2str(
        new Date(settings.defaultYear, this.filters.m1 - 1, 1)
      ),
      date_to: utils.db.date2str(
        new Date(settings.defaultYear, this.filters.m2, 0)
      ),
      invoice_firms: invoiceFirms.join(','),
      manexp: this.filters.manexp,
      finstat: this.filters.finstat,
      zaid: this.filters.zaid,
      plid: this.filters.plid,
    }
  }

  $onInit() {
    super.$onInit()
    this.$q.all([this.zaysTable.init$.promise]).then((resps) => {
      this.zaysTable.ctrl = resps[0]
    })
  }

  edit() {
    this.popup.state('zay.base.finansy', { id: this.zayId })
  }

  get zays() {
    if (this.zaysTable.ctrl) {
      return this.zaysTable.ctrl.rows
    } else {
      return []
    }
  }

  get _total_exp_income() {
    return this.zays.reduce((pv, cv) => pv + cv.expected_income_summ, 0)
  }

  get _total_exp_outcome() {
    return this.zays.reduce((pv, cv) => pv + cv.expected_outcome_summ, 0)
  }

  get _total_invoice_income() {
    return this.zays.reduce((pv, cv) => pv + cv.invoice_income_summ, 0)
  }

  get _total_invoice_outcome() {
    return this.zays.reduce((pv, cv) => pv + cv.invoice_outcome_summ, 0)
  }

  get _total_paid_income() {
    return this.zays.reduce((pv, cv) => pv + cv.paid_income_summ, 0)
  }

  get _total_paid_outcome() {
    return this.zays.reduce((pv, cv) => pv + cv.paid_outcome_summ, 0)
  }

  get _total_not_paid_income() {
    return this.zays.reduce((pv, cv) => pv + cv.not_paid_income_summ, 0)
  }

  get _total_not_paid_outcome() {
    return this.zays.reduce((pv, cv) => pv + cv.not_paid_outcome_summ, 0)
  }

  get _total_invoice_voznexp_summ() {
    return this.zays.reduce((pv, cv) => pv + cv.invoice_voznexp_summ, 0)
  }

  onLoadZays(rows) {
    this.total_exp_income = this._total_exp_income
    this.total_exp_outcome = this._total_exp_outcome
    this.total_invoice_income = this._total_invoice_income
    this.total_invoice_outcome = this._total_invoice_outcome
    this.total_invoice_balance =
      this.total_invoice_income - this.total_invoice_outcome
    this.total_paid_income = this._total_paid_income
    this.total_paid_outcome = this._total_paid_outcome
    this.total_paid_balance = this.total_paid_income - this.total_paid_outcome
    this.total_not_paid_income = this._total_not_paid_income
    this.total_invoice_voznexp_summ = this._total_invoice_voznexp_summ
  }
}

export const BalansComponent = {
  template: require('./balans.component.html'),
  styles: [require('./balans.component.scss')],
  bindings: {
    ourFirms: '<',
    managers: '<',
  },
  controller: BalansController,
  controllerAs: 'vm',
}
