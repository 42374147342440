import { ngOpenDialog } from '../popups'
import utils from '../utils'

const DEFAULT_WIDTH = 1024
const DEFAULT_HEIGHT = 768

/**
 * Сервис для работы с диалоговыми окнами.
 * @ngInject
 */
export default class PopupService {
  constructor($state) {
    this.$state = $state
  }

  /**
   * Открытие состояния в диалоговом окне.
   *
   * @param {string} matcher URL-матчер
   * @param {object} params параметры состояния
   * @param {number} width длина окна (px)
   * @param {number} height высота окна (px)
   * @returns {Promise<object>} результат выхода из диалога
   */
  state(matcher, params = {}, width = DEFAULT_WIDTH, height = DEFAULT_HEIGHT) {
    const url = this.$state.href(matcher, params)
    if (url === null) {
      throw new Error(`Invalid state: ${matcher}, ${params}`)
    }
    return ngOpenDialog(url.slice(3), width, height)
  }

  /**
   * Открыть старую PHP-форму.
   * @param {string} form Имя формы
   * @param {object} params параметры состояния
   * @param {number} width длина окна (px)
   * @param {number} height высота окна (px)
   */
  legacy(form, params = {}, width = DEFAULT_WIDTH, height = DEFAULT_HEIGHT) {
    const query = utils.url.buildQuery(params)
    $.fn.openPopup(`${form}&${query}`, width, height)
  }
}
