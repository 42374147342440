import utils from '../../utils'
import { emptyPlist } from '../../states/plist.states'
import { printUrl } from '../../popups'

function PutlistsController(
  $scope,
  $q,
  driversService,
  putlistService,
  putlistServiceApiV2,
  avtosService,
  pricepsService,
  planService,
  putlistModal,
  firmsService,
  delaypls
) {
  let drivers, avtos, priceps, plans

  const vm = {
    avto: null,

    add,
    addFrom,
    edit,
    delete: deleteList,
    search: updatePlists,
    exit,
    print,
    openLast,

    putlistTable: {
      options: {
        headers: {
          id: '№',
          date1: 'Начало',
          date2: 'Конец',
          file: 'Файл',
          driver: 'Водитель',
          avto_nomer: 'Тягач',
          pricep_nomer: 'Прицеп',
        },
        tablesorterOptions: {
          headers: {
            1: { sorter: 'dd.mm.yyyy' },
            2: { sorter: 'dd.mm.yyyy' },
            3: { sorter: 'zay/nomer' },
          },
          // sortList: [[0, 0]]
        },
      },
      init$: $q.defer(),
      rowfmt: (plist) => ({
        ...plist,
        date1: $.fn.dateutils.date2str_ddmmyyyy(plist.data1, '.'),
        date2: $.fn.dateutils.date2str_ddmmyyyy(plist.data2, '.'),
        file: plans[plist.pid]?._strid,
        driver: drivers[plist.driver1]?.fio,
        avto_nomer: avtos[plist.avto]?.nomer,
        pricep_nomer: priceps[plist.pricep]?.nomer,
      }),
      service: putlistService,
      ctrl: null,
    },
  }

  Object.defineProperty(vm, 'plist', {
    get: () => vm.putlistTable.ctrl?.selectedObject,
  })

  const data = {
    // plists: null,
    plans: null,
    drivers: null,
    avtos: null,
    priceps: null,
    ourFirms: null,
  }

  vm.data = data

  $scope.vm = vm

  init().then(() => {
    $scope.$watchGroup(
      ['vm.plistnum', 'vm.avto', 'vm.firm', 'vm.arendator', 'vm.arendatel'],
      delaypls(updatePlists, 500)
    )
  })

  function init() {
    return $q
      .all([
        driversService.get(),
        avtosService.get(),
        pricepsService.get(),
        planService.get(),
        firmsService.get({ kmbm__nashi: 1 }),
        putlistService.get(),
        vm.putlistTable.init$.promise,
      ])
      .then((resp) => {
        data.drivers = resp[0]
        data.avtos = resp[1].sort(utils.arraySort.byGosNomer)
        data.priceps = resp[2].sort(utils.arraySort.byGosNomer)
        data.plans = resp[3]
        data.ourFirms = resp[4]
        // data.plists = resp[5];

        vm.putlistTable.ctrl = resp[6]

        drivers = data.drivers.idlize()
        avtos = data.avtos.idlize()
        priceps = data.priceps.idlize()
        plans = data.plans.idlize()
      })
  }

  function updatePlists(x) {
    const filters = {}

    if (vm.plistnum) {
      filters.id__contains = vm.plistnum
    }

    if (vm.avto) {
      filters.avto = vm.avto
    }

    if (vm.firm) {
      filters.datediff_gt = 2
    }
    switch (vm.firm) {
      case 'zkm':
        filters.avto__pid = 13
        break
      case 'bm':
        filters.avto__pid = '17,19'
        break
      case 'th':
        filters.avto__pid = 179
        break
      default:
        delete filters.avto__pid
        delete filters.datediff_gt
    }

    if (vm.arendator) {
      filters.firm_id = vm.arendator
    }

    if (vm.arendatel) {
      filters.arendatel = vm.arendatel
    }

    return vm.putlistTable.ctrl.load(filters).then(selectLast)

    function selectLast(rows) {
      if (rows && rows.length > 0) {
        vm.plistId = rows[rows.length - 1].id
      }
    }
  }

  function add() {
    const modal = putlistModal.open({
      locals: { plist: emptyPlist, ...data },
    })
    return modal.closed.then(vm.putlistTable.ctrl.addOrUpdateRow)
  }

  function addFrom() {
    function newList() {
      const newList = utils.obj.copy(vm.plist)
      newList.id = null
      newList.pid = null
      newList.pid2 = null
      newList.marshrut = ''
      newList.data1 = new Date()
      newList.data2 = new Date()
      newList.realdriver = ''
      newList.realpricep = ''
      newList.city = +vm.plist.city
      newList.cover = +vm.plist.cover

      newList.plistu.speed = +newList.plistp.speed
      newList.plistp.speed = +newList.plistp.speed

      newList.plistu.ostatok = +newList.plistp.inbak
      newList.plistp.inbak = 0

      newList.plistu.time = ''
      newList.plistu.give = 0
      newList.plistu.gsm = 0
      newList.plistp.time = ''
      newList.plistp.weight = 0
      newList.plistp.aprob = 0
      newList.plistp.psgruz = 0
      return newList
    }

    const modal = putlistModal.open({
      locals: Object.assign({ plist: newList() }, data),
    })
    return modal.closed.then(vm.putlistTable.ctrl.addOrUpdateRow)
  }

  function edit(plist) {
    plist = plist || vm.plist
    const modal = putlistModal.open({
      locals: { ...data },
      resolve: {
        plist: () => putlistServiceApiV2.get({ id: plist.id }),
      },
    })
    return modal.closed.then(vm.putlistTable.ctrl.addOrUpdateRow)
  }

  function deleteList() {
    if (!confirm('Вы уверены, что хотите удалить данный путевой лист?')) {
      return
    }

    return putlistService
      .delete(vm.plistId)
      .then(vm.putlistTable.ctrl.removeRow)
  }

  function exit() {
    window.close()
  }

  function print() {
    printUrl(`print2.php?path=plist.php&id=${vm.plistId}`)
  }

  function openLast() {
    return edit(vm.putlistTable.ctrl.lastItem)
  }
}

export const PutlistsComponent = {
  template: require('./putlists.component.html'),
  styles: [require('./putlists.component.scss')],
  bindings: {},
  controller: PutlistsController,
  controllerAs: 'vm',
}
