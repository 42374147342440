import { WindowFormController } from '../../controllers/form-controller.class'

class TachFormController extends WindowFormController {
  /** @ngInject */
  constructor($scope, $injector, tachographService) {
    super($scope, $injector)
    this.api = tachographService
  }

  $onInit() {
    this.model = this.tach
  }
}

export const TachFormComponent = {
  template: require('./tach-form.component.html'),
  styles: [require('./tach-form.component.scss')],
  bindings: {
    tach: '<',
  },
  controller: TachFormController,
}
