import { CRUDServiceAPI2 } from './crud-service.class'

export class TarifiServiceApiV2 extends CRUDServiceAPI2 {
  subject = 'tarifi'
  key_arg = 'do'

  parse = (p) => {
    return p
  }

  format = (o) => {
    return o
  }
}
