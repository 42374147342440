import angular from 'angular'
import { ourFirms, legacyUsers, managers } from './resolvers'

export default angular
  .module('gruz.states.zays', [])
  .config(($stateRegistryProvider) => {
    $stateRegistryProvider.register({
      name: 'zay',
      url: '/zays',
      abstract: true,
    })

    $stateRegistryProvider.register({
      name: 'zay.list',
      url: '/list',
      component: 'zays',
      data: {
        title: 'Заявки',
      },
    })

    $stateRegistryProvider.register({
      name: 'zay.new',
      url: '/new',
      component: 'zayForm',
      resolve: {
        ourFirms,
        legacyUsers,
        managers,
      },
      data: {
        title: 'Новая заявка',
      },
    })

    $stateRegistryProvider.register({
      name: 'zay.base',
      url: '/{id}',
      abstract: true,
      resolve: {
        zay: ($stateParams, zaysServiceApiV2) => {
          return zaysServiceApiV2.get({ id: $stateParams.id })
        },
      },
    })

    $stateRegistryProvider.register({
      name: 'zay.base.detail',
      url: '/edit',
      component: 'zayForm',
      resolve: {
        ourFirms,
        legacyUsers,
        managers,
        plid: (zay, firmServiceApiV2) => {
          if (zay && zay.plid) {
            return firmServiceApiV2.get({ id: zay.plid })
          }
        },
        contracts: (zay, ourFirms, contractServiceApiV2) => {
          if (zay && zay.zaid) {
            const ourFirmsByCode = ourFirms.results.idlize((f) => f.kmbm)
            const server = ourFirmsByCode[zay.type]
            return contractServiceApiV2
              .get({ client: zay.zaid, server: server.id })
              .then((resp) => resp.results)
          }
        },
      },
      data: {
        title: (state) => `Заявка № ${state.params().id}`,
      },
    })

    $stateRegistryProvider.register({
      name: 'zay.base.finansy',
      url: '/finansy',
      component: 'zayFinansy',
      data: {
        title: (state) => `Финансы по заявке ${state.params().id}`,
      },
    })

    $stateRegistryProvider.register({
      name: 'zay.base.svodka',
      url: '/svodka',
      component: 'svodkaForm',
      resolve: {
        svodka: (zay, $http) => {
          return $http
            .get(`api.php?url=svodka&id=${zay.id}`)
            .then((resp) => resp.data)
        },
      },
      data: {
        title: (state) => `Сводка по грузу по заявке ${state.params().id}`,
      },
    })
  })
