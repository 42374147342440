import { printUrl } from '../../popups'
import utils, { lastDayOfMonth } from '../../utils'

/** @ngInject */
class TehosmotrController {
  /** @type {Array<Avto>} */
  avtos
  /** @type {Array<Avto>} */
  selAvtos
  /** @type {Array<Pricep>} */
  priceps
  /** @type {Array<Pricep>} */
  selPriceps

  constructor($scope, avtoServiceApiV2, pricepServiceApiV2) {
    const vm = this
    this.$scope = $scope
    this.avtoServiceApiV2 = avtoServiceApiV2
    this.pricepServiceApiV2 = pricepServiceApiV2

    $scope.vm = vm
  }

  /** Выбранный тягач. */
  get avto() {
    if (Array.isArray(this.selAvtos) && this.selAvtos.length > 0) {
      return this.selAvtos[0]
    } else {
      return null
    }
  }

  /** Выбранный прицеп. */
  get pricep() {
    if (Array.isArray(this.selPriceps) && this.selPriceps.length > 0) {
      return this.selPriceps[0]
    } else {
      return null
    }
  }

  $onInit() {
    this.firm = null
    this.tehosmDateFilter = lastDayOfMonth()
  }

  printAvtos() {
    printUrl(`print2.php?path=tehosmall.php&_id=${this.firm.id}&_type=avto`)
  }

  printPriceps() {
    printUrl(`print2.php?path=tehosmall.php&_id=${this.firm.id}&_type=pricep`)
  }

  printBefore() {
    const dmY = utils.db.date2dmy(this.tehosmDateFilter)
    printUrl(`print2.php?path=tehosmall.php&_id=-1&_type=avto&date=${dmY}`)
  }

  onFirmChange() {
    if (!this.firm) {
      return
    }
    this.avtoServiceApiV2
      .get({ firmId: this.firm })
      .then((resp) => (this.avtos = resp.results))

    this.pricepServiceApiV2
      .get({ firmId: this.firm })
      .then((resp) => (this.priceps = resp.results))
  }

  /** Сохранение даты техосмотра для тягача. */
  saveAvtoTehosmotr() {
    this.avtoServiceApiV2.patch({
      id: this.avto.id,
      tehosmdata: this.avto.tehosmdata,
    })
  }

  /** Сохранение даты техосмотра для тягача. */
  savePricepTehosmotr() {
    this.pricepServiceApiV2.patch({
      id: this.pricep.id,
      tehosmdata: this.pricep.tehosmdata,
    })
  }

  /**
   * Устарела ли дата техосмотра
   * @param {Avto|Pricep} entity
   * @returns {boolean}
   */
  ifLate(entity) {
    const now = Date.now() / 1000
    return now > parseInt(entity.tehosmdata || '0')
  }
}

export const TehosmotrComponent = {
  template: require('./tehosmotr.component.html'),
  styles: [require('./tehosmotr.component.scss')],
  bindings: {
    firms: '<',
  },
  controller: TehosmotrController,
}
